var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "300", persistent: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on }) {
              return [_vm._t("activador", null, { on: on })]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0" },
        [
          _c(
            "v-sheet",
            {
              staticStyle: {
                "border-bottom-right-radius": "0",
                "border-bottom-left-radius": "0",
              },
              attrs: { color: "primary pa-2" },
            },
            [
              _c("h2", { staticClass: "white--text title text-center" }, [
                _vm._v(_vm._s(_vm.headerTitle)),
              ]),
            ]
          ),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center pa-10 mb-5" },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "grey", indeterminate: "" },
                  }),
                ],
                1
              )
            : _c(
                "v-sheet",
                {
                  staticClass: "pa-4 pt-6 pl-6",
                  staticStyle: { overflow: "auto" },
                  attrs: { "max-height": "400" },
                },
                [
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "ma-0",
                      attrs: { mandatory: false, "hide-details": "" },
                      model: {
                        value: _vm.option,
                        callback: function ($$v) {
                          _vm.option = $$v
                        },
                        expression: "option",
                      },
                    },
                    [
                      _vm._l(_vm.options, function (dado, index) {
                        return [
                          _c("v-radio", {
                            key: !_vm.valueKey ? dado : dado[_vm.valueKey],
                            staticClass: "mb-5",
                            attrs: {
                              "data-test-id": `dado-${index}`,
                              label: !_vm.labelKey ? dado : dado[_vm.labelKey],
                              value: dado,
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
          _c("v-divider"),
          _c(
            "v-sheet",
            { staticClass: "d-flex", attrs: { color: "pa-2" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-test-id": "cancelar-dialogo",
                    text: "",
                    color: "primary",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-bold text-capitalize subtitle-1",
                    },
                    [_vm._v("Cancelar")]
                  ),
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-test-id": "confirmar-dialogo",
                    text: "",
                    color: "primary",
                  },
                  on: { click: _vm.confirm },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-bold text-capitalize subtitle-1",
                    },
                    [_vm._v("Confirmar")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }