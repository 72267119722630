<template>
  <div>
    <slot :data="entidades" :loading="loading"></slot>
  </div>
</template>

<script>
import catalogoService from "@/services/catalogoService";

export default {
  name: "AsyncEntidades",
  props: {
    fullList: {
      type: Boolean,
      default: false,
    },
    cidade: {
      type: String,
      default: "",
    },
    estado: {
      type: String,
      default: "",
    },
    publicoAlvo: {
      type: String,
      default: "",
    },
    administradora: {
      type: Array,
    },
    corretora: {
      type: String,
      default: "",
    },
    getDataOnMount: {
      type: Boolean,
      default: true,
    },
    loadEntidades: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      entidades: [],
    };
  },
  watch: {
    async loadEntidades() {
      if (this.loadEntidades) {
        await this.getData();
      }
    },
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await catalogoService.getEntidades({
          cidade: this.cidade,
          uf: this.estado,
          publicoAlvo: this.publicoAlvo,
          administradora: this.administradora,
          corretora: this.corretora,
        });
        this.entidades = data;
        this.$emit("success", data);
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
        this.$emit("error");
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    if (this.getDataOnMount && (this.fullList || (this.cidade && this.estado && this.publicoAlvo))) {
      await this.getData();
    }
  },
};
</script>

<style></style>
