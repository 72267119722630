<template>
  <v-dialog v-model="dialog" max-width="300" persistent>
    <template v-slot:activator="{on}">
      <slot name="activador" :on="on"></slot>
    </template>
    <v-card class="pa-0">
      <v-sheet color="primary pa-2" style="border-bottom-right-radius: 0; border-bottom-left-radius: 0">
      <h2 class="white--text title text-center">{{headerTitle}}</h2>
      </v-sheet>
       <div v-if="loading" class="d-flex justify-center pa-10 mb-5">
          <v-progress-circular color="grey" indeterminate></v-progress-circular>
      </div>
      <v-sheet max-height="400" style="overflow: auto" class="pa-4 pt-6 pl-6" v-else>
        <v-radio-group v-model="option" :mandatory="false" class="ma-0" hide-details>
          <template v-for="(dado, index) in options">
            <v-radio :data-test-id="`dado-${index}`" class="mb-5" :label="!labelKey ? dado : dado[labelKey]" :value="dado" :key="!valueKey ? dado : dado[valueKey]"></v-radio>
          </template>
        </v-radio-group>
      </v-sheet>
       <v-divider></v-divider>
       <v-sheet color="pa-2" class="d-flex">
        <v-btn :data-test-id="'cancelar-dialogo'" text color="primary" @click="dialog = false"><span class="font-weight-bold text-capitalize subtitle-1">Cancelar</span></v-btn>
        <v-spacer></v-spacer>
        <v-btn :data-test-id="'confirmar-dialogo'" text color="primary" @click="confirm"><span class="font-weight-bold text-capitalize subtitle-1">Confirmar</span></v-btn>
       </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    optionSelected: {
      type: String,
      required: false,
    },
    labelKey: {
      type: String,
      required: false,
    },
    valueKey: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      option: '',
    };
  },
  created() {
    if (this.options && this.options.length) {
      const optionS = this.optionSelected ? this.optionSelected.toString().toUpperCase() : this.optionSelected;
      const index = this.options.findIndex((f) => f.id !== null && f.id !== undefined && f.id.toString().toUpperCase() === optionS);
      if (index >= 0) {
        this.option = this.options[index];
      }
    }
  },
  watch: {
    dialog(flag) {
      if (flag) this.$emit('open');
      else this.$emit('close');
    },
  },
  methods: {
    confirm() {
      if (this.option) {
        this.$emit('select', this.option);
        this.dialog = false;
      }
    },
  },
};
</script>

<style>
</style>
