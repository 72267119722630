var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    {
      staticClass: "pa-0",
      staticStyle: { overflow: "hidden" },
      attrs: { elevation: "2" },
    },
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-avatar",
            { attrs: { dark: "", color: "grey", size: "34" } },
            [
              _c("span", { staticClass: "white--text subtitle-1 mt-1" }, [
                _vm._v(_vm._s(_vm._f("avatarName")(_vm.avatar))),
              ]),
            ]
          ),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                { staticClass: "subtitle-2 text-uppercase" },
                [_vm._v(_vm._s(_vm.nome))]
              ),
              _c(
                "div",
                { staticClass: "align-center" },
                [
                  _vm.valor < _vm.valorSemDesconto
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-list-item-subtitle",
                            {
                              staticClass:
                                "caption text-decoration-line-through",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("currency")(_vm.valorSemDesconto))
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "caption" },
                            [_vm._v(_vm._s(_vm._f("currency")(_vm.valor)))]
                          ),
                        ],
                        1
                      )
                    : _vm.comOdonto
                    ? _c("v-list-item-subtitle", { staticClass: "caption" }, [
                        _vm._v(_vm._s(_vm._f("currency")(_vm.valorOdonto))),
                      ])
                    : _c("v-list-item-subtitle", { staticClass: "caption" }, [
                        _vm._v(_vm._s(_vm._f("currency")(_vm.valor))),
                      ]),
                  _c(
                    "span",
                    {
                      staticClass:
                        "caption grey--text text-uppercase float-right",
                    },
                    [_vm._v(_vm._s(_vm.tipo))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }