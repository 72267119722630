<template>
  <v-list elevation="2" class="pa-0" style="overflow: hidden;">
    <v-list-item>
      <v-list-item-avatar dark color="grey" size="34">
        <span class="white--text subtitle-1 mt-1">{{avatar | avatarName}}</span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2 text-uppercase">{{nome}}</v-list-item-title>
        <div class="align-center">
        <div v-if="valor < valorSemDesconto">
          <v-list-item-subtitle class="caption text-decoration-line-through">{{valorSemDesconto | currency}}</v-list-item-subtitle>
          <v-list-item-subtitle class="caption">{{valor | currency}}</v-list-item-subtitle>
        </div>
          <v-list-item-subtitle v-else-if="comOdonto" class="caption">{{valorOdonto | currency}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else class="caption">{{valor | currency}}</v-list-item-subtitle>
          <span class="caption grey--text text-uppercase float-right">{{tipo}}</span>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>

export default {
  props: {
    nome: {
      type: String,
      default: 'Nome',
    },
    valor: {
      default: 0,
    },
    valorSemDesconto: {
      deafult: 0,
    },
    valorOdonto: {
      deafult: 0,
    },
    tipo: {
      default: '--',
    },
    avatar: {
      default: '',
    },
    comOdonto: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>
