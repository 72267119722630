import { render, staticRenderFns } from "./BaseCardBeneficiary.vue?vue&type=template&id=3db95ab8"
import script from "./BaseCardBeneficiary.vue?vue&type=script&lang=js"
export * from "./BaseCardBeneficiary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VList,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3db95ab8')) {
      api.createRecord('3db95ab8', component.options)
    } else {
      api.reload('3db95ab8', component.options)
    }
    module.hot.accept("./BaseCardBeneficiary.vue?vue&type=template&id=3db95ab8", function () {
      api.rerender('3db95ab8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BaseCardBeneficiary.vue"
export default component.exports